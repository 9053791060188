
import React, { useEffect, useState, useCallback } from 'react';
import {useParams} from 'react-router-dom';
import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    Marker,
    Polyline
} from 'react-google-maps';
import Card from '@mui/material/Card';
import '../../App.css';


const MapLocation = ({ paths }) => {
    const {id}=useParams();
    let LatLng={ lat: paths[0].lat, lng: paths[0].lng };


    const [progress, setProgress] = useState();

    const icon1 = {
        url: "bus.png",
        scaledSize: new window.google.maps.Size(40, 40),
        anchor: new window.google.maps.Point(20, 20),
        scale: 0.7,
    };

    useEffect(() => {
        //console.log(typeof (paths[0].lat));
        //console.log(id)

        let nextLineLatLng = new window.google.maps.LatLng(
            paths[0].lat,
            paths[0].lng,
        
        );
        setProgress(nextLineLatLng)
        //console.log(progress);
    }, [paths])

    return (
        <Card variant="outlined">
            {/* <div className='btnCont'>
        <Button variant="contained" onClick={startSimulation}>Start Simulation</Button>
      </div> */}
    
            {progress && (<div className='gMapCont'>
                <GoogleMap
                    defaultZoom={17}
                    center={progress}
                >

                    {/* 
          {stops.data.map((stop, index) => (

            <Marker
              key={index}
              position={{
                lat: stop.lat,
                lng: stop.lng
              }}
              title={stop.id}
              label={`${index + 1}`}
            />
          ))} */}

                    {progress && (
                        <Marker
                            icon={icon1}
                            position={progress}
                        />)}

                </GoogleMap>
            </div>)}
        </Card>
    )
};

export default withScriptjs(
    withGoogleMap(
        MapLocation
    )
)