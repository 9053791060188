import React, { useEffect, useState } from 'react';
import './App.css';
import WrappedMap from './components/gMap/location';
import config from './components/gMap/config';
import useFetch from './hooks/useFetch';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';

const headers = {
  'x-api-key':process.env.REACT_APP_XAPIKEY,
  
  "ACCESS-CONTROL-ALLOW-ORIGIN": "*",
  "ACCESS-CONTROL-ALLOW-CREDENTIALS": "TRUE",
  "ACCESS-CONTROL-ALLOW-HEADERS": "ORIGIN, X-REQUESTED-WITH, CONTENT-TYPE, ACCEPT, AUTHORIZATION",
  "Origin": "https://tracking.freshbus.com/"
};


function App() {
const params=new URLSearchParams(document.location.search);
  // const { data: paths} = useFetch('https://61a4a0604c822c0017041d33.mockapi.io/shuttle/v1/path');
  const { data: stops } = useFetch('https://61a4a0604c822c0017041d33.mockapi.io/shuttle/v1/stops');
  const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${config.mapsKey}`;

  const [paths, setPaths] = useState([{lng: 79.203735, lat: 13.497608}]);

  
  const getData = () => {
    let setPathsArray = [];
    try {

      fetch('https://api.freshbus.com/gps_data?id=' +params.get('serviceMapId') , {
       
        method: 'GET', // or 'GET', 'PUT', 'DELETE'
        headers: headers,
      
      })
        .then(response => response.json())
        .then(data => {
          // handle the response data
          //console.log(data)
        //  for (let index = 0; index < data.length; index++) {
            const element = data;
            if (element.latitude ) {
              setPathsArray.push({
                "lng": parseFloat(element.longitude),
                "lat": parseFloat(element.latitude),
                 "sern": (element.serviceNumber),
                 "drin":element.driverName,
                 "drinum": element.driverNumber,
                  "ss":element.serviceStartTime,
                 "se":element.serviceEndTime,
                 "veh":element.vehicleNumber,
                 "jd":element.journeyDate,
                 'routeName': element.routeName,
                 "refresh": new Date(element.refreshedAt * 1000).toLocaleTimeString(),
                 "soc":element.SOC

                   })
            }

        //  }
        
          //console.log(setPathsArray, "...")
          setPaths(setPathsArray);
        })
        .catch(error => {
          console.log(error)
          // handle errors
        });

    } catch (e) {
      console.log(e);
    }
  }


  useEffect(() => {
    //console.log(paths);
    //console.log(process.env.REACT_APP_XAPIKEY)
    const intervalCall = setInterval(() => {
      getData();
    }, 3000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);





  return (
    
    // <><BrowserRouter>
      
    //     <Route path="bus/:id" element={<WrappedMap />}>
    //     {/* <Route path="bus/:123456" element={<LinearProgress />}> */}

    //     </Route>
    
    // </BrowserRouter>   
    <div className="App">
      <div className="header"> 
        <img className='logoImage' src='logos.jpg'></img>
      </div>
      <div className="mapBody">
        {paths && stops ?
            <WrappedMap
              paths={paths}
              stops={stops}
              googleMapURL={mapURL}
              loadingElement={<div style={{ height: `90%` }} />}
              containerElement={<div className='mapContainer' />}
              mapElement={<div style={{ height: `90%` }} />} />
            :
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          }
      </div>
      <div className="metadata">
        <span className="s1">
          <p>Starting At</p>
          <p class="s3">{paths[0].ss}</p>
        </span>
        <span className="route">
          <p>Route</p>
          <p class="s3">{paths[0].routeName}</p> 
        </span>
        <span className="s2">
          <p>Reaching At</p>
          <p class="s3">{paths[0].se}</p>
          </span>
      </div>
      <div className="metadata">
        <span  className="s1">
          <p>ServiceNumber</p>
          <p class="s3">{paths[0].sern}</p>
        </span>
        <span className="s2">
          <p>Journey Date</p>
          <p class="s3">{paths[0].jd}</p>
          </span>
      </div>
      <div className="metadata">
        <span  className="s1">
          <p>DriverName</p>
          <p class="s3">{paths[0].drin}</p>
        </span>
        <span className ="s2">
          <p>VehicleNumber</p>
          <p class="s3">{paths[0].veh}</p>
        </span>
      </div>

      <div className="metadata">
        <span  className="s1">
          <p>Last Updated</p>
          <p class="s3">{paths[0].refresh}</p>
        </span>
        <span className ="s2">
          <p>SOC</p>
          <p class="s3">{paths[0].soc}</p>
        </span>
      </div>
    
      <div className="data" href="tel:{paths[0].drinum}">
              <PhoneOutlinedIcon style={{color:'#FAE823'}}/>&nbsp;&nbsp;
              <a  className="data2 s3"href={"tel:"+paths[0].drinum}> +91 {paths[0].drinum}</a> 
          
          </div>
      </div>
  );
}

export default App;